<template>
  <div>
    <r-popover
      content-class="data-download"
      :max-width="'351'"
      :value="!isOpen"
    >
      <template
        #activator="{ on }"
      >
        <r-button
          transparent
          class="flex"
          @click="clickFunc"
        >
          <p
            class="text-rocky"
          >
            {{ isOpen == false ? valueHidden : value }}
          </p>
          <svg
            :class="'ml-2 r-drop-down__arrow ' + (isOpen == false ? 'open' : '')"
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00153 1.82999L6.45177 0.172415C6.90639 -0.135128 7.52351 -0.014819 7.83015 0.441133C8.13679 0.897084 8.01684 1.51602 7.56222 1.82356L4.59679 3.82965C4.40671 3.95824 4.18821 4.01203 3.97588 3.99776C3.77892 4.00141 3.57892 3.94646 3.40321 3.82758L0.437779 1.8215C-0.016836 1.51395 -0.136792 0.895018 0.169849 0.439066C0.476491 -0.0168856 1.09361 -0.137194 1.54823 0.170349L4.00153 1.82999Z"
              fill="#3D75E4"
            />
          </svg>
        </r-button>
      </template>
    </r-popover>
  </div>
</template>

<script>

export default {
  name: 'PopoverDrop',
  props: {
    value: {
      type: [String, null],
      default: null
    },
    valueHidden: {
      type: [String, null],
      default: null
    }
  },
  data: () => ({
    isOpen: true
  }),
  mounted() {
    const results = window.localStorage.isOpen;
    if (results == 'false' || results == 'true') {
      this.isOpen = JSON.parse(results);
      this.$emit('itemCheck', this.isOpen);
    }
  },
  methods: {
    clickFunc() {
      this.isOpen = !this.isOpen;
      this.$emit('onClick', this.isOpen);
      delete window.localStorage.isOpen;
      window.localStorage.isOpen = this.isOpen;
    }
  }
};
</script>

<style scoped lang="scss">

.r-popover {
  display: flex;
  align-items: center;

  .r-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &::v-deep {
    border-radius: 8px;
  }

  &__wrapper {
    padding: 16px;

    .r-button {
      display: flex;
      align-items: center;
      color: #04153E;
      opacity: 0.72;
      padding: 12px 26px 12px 16px !important;
      border-left: 4px solid transparent;
      border-radius: 0 !important;

      &:hover {
        opacity: 1;
        border-left-color: #3D75E4;
      }

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}
.r-drop-down__arrow.open {
  transform: rotate(180deg);
}
.r-drop-down__arrow {
  margin-right: 8px;
  transition: transform .2s ease;
}
::v-deep .r-checkbox__selected {
  left: auto !important;
}

</style>
