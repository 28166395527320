<template>
  <label class="r-switcher">
    <input
      type="checkbox"
      v-model="model"
    >
    <span class="r-switcher__slider" />
    <div class="r-switcher__label">
      {{ label }}
      <slot
        name="label"
        v-if="!isLabel"
      />
    </div>
  </label>
</template>

<script>

export default {
  name: 'RirSwitcher',
  props: {
    value: {
      type: Boolean
    },
    label: {
      type: String
    }
  },
  computed: {
    isLabel() {
      return !!this.label;
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        delete window.localStorage.isOpen;
        window.localStorage.isOpen = val;
        this.$emit('input', val);
      }
    }
  },
  mounted() {
    const results = window.localStorage.isOpen;
    if (results == 'false' || results == 'true') {
      this.value = JSON.parse(results);
      this.$emit('itemCheck', this.value);
    }
  }
};
</script>
<style lang="scss" scoped>

.r-switcher {
display: flex;
align-items: center;
position: relative;
padding-left: 28px;
cursor: pointer;
user-select: none;

&>input {
opacity: 0;

&:checked ~ .r-switcher {
&__slider {
opacity: 1;
&::before {
transform: translateX(12px);
}
}

&__label {
opacity: 1;
}
}
}

&__slider {
position: absolute;
left: 0;
margin-right: 12px;
background-color: var(--rir-rocky);
opacity: .48;
transition: .4s;
border-radius: 10px;
width: 32px;
height: 20px;

&::before {
position: absolute;
content: "";
height: 16px;
width: 16px;
left: 2px;
bottom: 2px;
background-color: var(--rir-amelie);
box-shadow: 0 2px 2px 0 #13398652;
transition: .4s;
border-radius: 50%;
}
}

&__label {
color: var(--rir-titanic);
font-weight: 500;
font-size: 16px;
line-height: 20px;
opacity: .72;
min-height: 24px;
display: flex;
align-items: center;
}
}

</style>
