<template functional>
  <div
    class="ListItem"
    :class="{ active: props.active }"
    v-on="listeners"
  >
    <slot>TEXT</slot>
  </div>
</template>

<script>
// https://stackoverflow.com/questions/50288996/how-to-emit-an-event-from-vue-js-functional-component
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.ListItem {
  cursor: pointer;
  opacity: 0.72;
  padding-left: 1rem;
  padding-right: 0.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.ListItem:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 254, var(--tw-bg-opacity));
}
.ListItem.active {
  opacity: 1;
}
.ListItem.active::before {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 117, 228, var(--tw-bg-opacity));
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 0.25rem;
}
</style>
