<template>
  <div
    class="Tag"
    :class="`bg-${bg}`"
  >
    <slot>TEXT</slot>
  </div>
</template>

<script>
// https://stackoverflow.com/questions/50288996/how-to-emit-an-event-from-vue-js-functional-component
export default {
  props: {
    bg: {
      type: String,
      default: 'alien'
    }
  }
};
</script>

<style>
.Tag {
  border-radius: 9999px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 1.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}
</style>
