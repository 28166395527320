<template>
  <main
    class="mb-10"
    v-if="loaded"
  >
    <h1 class="cheddar">
      Календарь событий
    </h1>
    <div class="mt-8 mb-4 flex justify-between tags_wrapper">
      <tag-carousel
        :items="categories"
        @select="onChangeType"
        @selectFree="onChangeTypeFree"
      />
      <!-- <div
        class="
          mb-4
          snap snap-x snap-mandatory
          overflow-auto
          no-scrollbar
          flex
          gap-x-2
        "
      >
        <CTagBtn
          :active="!getParams.type_id"
          @click="
            getParams.type_id = null;
            filterBy();
          "
          >Все категории</CTagBtn
        >
        <CTagBtn
          v-for="It in categories"
          :key="It.id"
          :active="It.id === getParams.type_id"
          @click="
            getParams.type_id === It.id
              ? (getParams.type_id = null)
              : (getParams.type_id = It.id);
            filterBy();
          "
          >{{ It.name }}</CTagBtn
        >

      </div>-->
    </div>
    <div class="mt-5 mb-5 flex justify-between tags_wrapper">
      <div class="flex">
        <div class="opacity-48">
          Показывать
        </div>
        <c-dropdown
          class="ml-3 mr-3"
        >
          <!-- Button content -->
          <template #button>
            <div
              class="flex items-center"
              @click="dropdownAsDatePicker = false"
            >
              <span class="mr-2">
                {{
                  dropdownAsDatePicker
                    ? "За период"
                    : periodTimeObj[periodTimeKey] || "За всё время"
                }}
              </span>
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
                />
              </svg>
            </div>
          </template>

          <!-- Opened dropdown content -->
          <template #content="{ close }">
            <nav
              v-if="!dropdownAsDatePicker"
              style="z-index: 1111111"
              class="
                flex flex-col
                w-42
                bg-white
                shadow-lg
                rounded-8
                bg-white
              "
            >
              <c-list-item
                @click="
                  filterPeriod(null, false);
                  close();
                "
                :active="!getParams.sessions_to"
              >
                За все время
              </c-list-item>
              <c-list-item
                v-for="(Val, Key, idx) in periodTimeObj"
                :key="idx"
                @click="
                  filterPeriod(Key, false);
                  close();
                "
                :active="periodTimeKey === Key"
              >
                {{ Val }}
              </c-list-item>
              <c-list-item @click="dropdownAsDatePicker = true">
                <span class="text-rocky">Указать даты</span>
              </c-list-item>
            </nav>
            <!-- component -->
            <div
              v-else
              class="flex flex-col shadow shadow-lg bg-white rounded-8"
            >
              <date-picker
                v-model="range"
                is-range
              />
              <button
                @click="
                  filterBy(true);
                  close();
                "
                class="button mb-4 mx-4"
              >
                Искать
              </button>
            </div>
          </template>
        </c-dropdown>
      </div>
      <div
        class="flex"
        <c-dropdown
        left
      >
        <!-- Button content -->
        <template #button>
          <div
            class="flex items-center"
          >
            <r-icon
              icon="geopoint"
              fill="rocky"
            />
            <span class="ml-1 mr-2 text-rocky">
              {{
                periodGeoObj.filter(item => item?.id == periodGeoKey)[0].name || "Все населенные пункты"
              }}
            </span>
            <svg
              class="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="rocky"
            >
              <path
                d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
                fill="#3D75E4"
              />
            </svg>
          </div>
        </template>

        <!-- Opened dropdown content -->
        <template #content="{ close }">
          <nav
            class="
                flex flex-col
                w-62
                bg-white
                shadow-lg
                rounded-8
                bg-white
              "
          >
            <c-list-item
              v-for="(Val, Key, idx) in periodGeoObj"
              :key="idx"
              @click="
                filterPeriod(Val.id, true);
                close();
              "
              :active="periodGeoKey === Val.id"
            >
              {{ Val.name }}
            </c-list-item>
          </nav>
        <!-- component -->
        </template>
        </c-dropdown>
        <rirSwitcher
          label="Карта"
          @input="successCheck"
          @itemCheck="isOpenCheck"
          :value="success"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        class="my-5"
        v-if="success"
      >
        <r-map
          :zoom="12"
          map-search
          class="h-400px"
        >
          <ymap-marker
            :key="count"
            v-for="(marker, idx) in ymapMarkers"
            :coords="marker.center"
            :hint-content="marker.address"
            cluster-name="main"
            :icon="{
              layout: 'default#image',
              imageHref:
                'https://storage.rosatom.city/platform-test/vue-culture/map/marker.svg',
            }"
            :options="{ hideIconOnBalloonOpen: false }"
            :balloon="{ body: clusterBalloon(marker)}"
          />
        </r-map>
      </div>
    </transition>
    <!-- <pre>{{mapVenues}}</pre> -->
    <!-- <pre>{{ymapMarkers}}</pre> -->

    <center
      class="h-screen-sm flex"
      v-if="!list.length"
    >
      <h3 class="taleggio m-auto">
        Ни одно событие не удовлетворяет
        <br>условиям фильтра
      </h3>
    </center>
    <transition-group
      name="fade"
      class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
    >
      <card-event
        v-for="It in list"
        :key="It.id"
        :it="It"
      />
    </transition-group>

    <button
      class="button mt-10 w-full"
      v-if="hasLoadMore"
      @click="loadMore"
    >
      Показать ещё
    </button>

      <!--
    <PreData class="my-10">Карта:{{ mapVenues }}</PreData>
    <pre class="mt-10">list:{{ list }}</pre>
    -->
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
// import TagBtn from "@/components/TagBtn.vue";
import Dropdown from '@/components/Dropdown.vue';
import ListItem from '@/components/ListItem.vue';
import CardEvent from '@/components/CardEvent.vue';
import PopoverDrop from '@/components/PopoverDrop.vue';
// import PreData from "@/components/PreData.vue";

// https://vcalendar.io/datepicker.html
// import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import rirSwitcher from '@/components/rirSwitcher.vue';
import TagCarousel from '../components/TagCarousel';
import RMap from '../components/RirMap';

const getParams = {
  per_page: 12,
  'sort[is_main]': 'desc',
  'sort[started_at]': 'asc'
};
const API = 'afisha/events';

export default {
  name: 'List',
  components: {
    TagCarousel,
    CDropdown: Dropdown,
    CListItem: ListItem,
    RMap,
    PopoverDrop,
    // PreData,
    CardEvent,
    rirSwitcher,
    //
    // Calendar,
    DatePicker
  },

  data() {
    return {
      loaded: false,
      success: true,
      count: 0,
      getParams: {
        ...getParams,
        type_id: null,
        sessions_from: null, // this.$dayjs().format("YYYY-MM-DD"),
        sessions_to: null
      },
      list: [],
      categories: [],
      mapVenues: [],
      meta: null,
      // https://day.js.org/docs/en/manipulate/start-of
      periodTimeObj: {
        D: 'Сегодня',
        w: 'На этой неделе',
        M: 'В этом месяце'
      },
      periodGeoObj: {},
      periodTimeKey: null, // 'За всё время',
      periodGeoKey: '00000',
      //
      dropdownAsDatePicker: false,
      range: {
        start: Date.now(),
        end: Date.now()
      },
      map: null
    };
  },

  computed: {
    periodTime() {
      return this.dropdownAsDatePicker ? 'За период' : 'За всё время';
    },
    hasLoadMore() {
      // return this.list && !(this.meta?.current_page === this.meta?.last_page);
      return (
        this.meta?.last_page > 1
        && !(this.meta?.current_page === this.meta?.last_page)
      );
    },
    nextPage() {
      return this.meta?.current_page + 1;
    },

    // ymapMarkers() {
    //   return (
    //     this.list.length &&
    //     this.list
    //       .filter((It) => It.city.center)
    //       .map((It) => ({
    //         center: It.city.center,
    //         hint: It.title,
    //         img: It.image_previews.medium,
    //       }))
    //   );
    // },
    // ymapMarkers() {
    //   // TODO
    //   return (
    //     this.mapVenues.length &&
    //     this.mapVenues.map((It) => ({
    //       center: It.venues[0].center,
    //       hint: It.venues[0].address,
    //       // address: It.venues[0].address,
    //       img: It.image_previews.small,
    //       title: It.title
    //     }))
    //   );
    // },

    ymapMarkers() {
      return (
        this.mapVenues.length
        && this.mapVenues
          .map(It => It.venues.reduce(
            (acc, it) => acc.concat({
              ...it,
              title: It.title,
              img: It.image
            }),
            []
          ))
          .flat()
      );
    }
  },

  created() {
    this.getData();
  },
  methods: {
    clusterBalloon(marker) {
      return `<a
          href="/events/${marker.event_id}"
          class="r-map-balloon pointer">
        ${marker.img ? `<div
          class="r-map-balloon__img"
          style=""
        >
          <img
            src='${marker.img}'
            alt='${marker.title}'
          >
        </div>` : ``}
        <div class="pa-4">
          <p class="roquefort mb-1">
            ${marker.title}
          </p>
          <p class="сaprino opacity-72">
            ${marker.address}
          </p>
        </div>
      </a>`;
    },
    markersWasAdd() {
      this.centeredMap();
    },
    initMap(e) {
      this.map = e;
    },
    centeredMap() {
      if (this.map && this.ymapMarkers.length > 0) {
        const pointsList = [];
        if (this.ymapMarkers.length > 0) {
          this.ymapMarkers.forEach(item => {
            if (item?.center?.length > 0) {
              pointsList.push(item.center);
            }
          });
        }
        this.$nextTick(() => {
          if (pointsList.length > 0) {
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 100,
              duration: 300
            });
          }
        });
      }
    },
    isOpenCheck(item) {
      this.success = item;
      this.count++;
    },
    successCheck() {
      this.success = !this.success;
    },
    formatDayRange() {
      return {
        sessions_from: this.$dayjs(this.range.start).format('YYYY-MM-DD'),
        sessions_to: this.$dayjs(this.range.end)
          .add(1, 'day')
          .format('YYYY-MM-DD')
      };
    },
    onChangeType(e) {
      console.log(e);
      this.getParams.sessions_from = this.$dayjs()
        .format('YYYY-MM-DD');
      this.getParams.sessions_to = this.$dayjs()
        .add(3, 'month')
        .format('YYYY-MM-DD');

      if (e.id !== '0000') {
        this.getParams.type_id = e.id;
      } else {
        this.getParams.type_id = null;
      }
      this.filterBy();
    },
    onChangeTypeFree(e) {
      if (e.active) {
        this.getParams.free_only = true;
      } else {
        this.getParams.free_only = null;
      }
      this.filterBy();
    },
    balloonTemplate(obj) {
      /*
      switch (CASE) {
          case 1:
            //
            break;
          default:
            break;
        }
      1 === <p> Дата: ${ this.$dayjs(obj.datetime).format('D MMMM YYYY')}</p>
      >1 === <p>Несколько сеансов. Уточняйте на месте</p>
      0 === Проходит ежедневно. Уточняйте на месте
      */
      let TPL;
      if (obj.session_count === 1) {
        TPL = `<a href="/events/${obj.event_id}" class="block w-72">
          <picture class="block aspect-10/16">
            <img class="object-cover" src="${obj.img}" />
          </picture>
          <div class="p-4">
            <h3 class="bryndza mb-1">${obj.title}</h3>
            <p>${obj.address}</p>
            <p> Дата: ${this.$dayjs(obj.datetime).format(
    'D MMMM YYYY, H:mm'
  )}</p>
          </div>
        </a>
      `;
      }
      if (obj.session_count > 1) {
        TPL = `<a href="/events/${obj.event_id}" class="block w-72">
          <picture class="block aspect-10/16">
            <img class="object-cover" src="${obj.img}" />
          </picture>
          <div class="p-4">
            <h3 class="bryndza mb-1">${obj.title}</h3>
            <p>${obj.address}</p>
            <p>Несколько сеансов. Уточняйте на месте</p>
          </div>
        </a>
      `;
      }
      if (obj.session_count === 0) {
        TPL = `<a href="/events/${obj.event_id}" class="block w-72">
          <picture class="block aspect-10/16">
            <img class="object-cover" src="${obj.img}" />
          </picture>
          <div class="p-4">
            <h3 class="bryndza mb-1">${obj.title}</h3>
            <p>${obj.address}</p>
            <p>Проходит ежедневно. Уточняйте на месте</p>
          </div>
        </a>
      `;
      }
      return TPL;
    },
    async getData() {
      const _this = this;
      try {
        getParams.sessions_from = this.$dayjs()
          .format('YYYY-MM-DD');
        getParams.sessions_to = this.$dayjs()
          .add(3, 'month')
          .format('YYYY-MM-DD');
        this.getParams = getParams;
        const [listRes, categoriesRes, mapRes, city] = await Promise.all([
          this.$axios.get(API, { params: {...getParams, 'sort[is_main]': 'desc',
            'sort[started_at]': 'asc'} }),
          this.$axios.get('afisha/event-types'),
          this.$axios.get('afisha/events/map', { params: getParams }),
          this.$axios.get('lists/cities')
        ]);
        this.periodGeoObj = [{
          id: '00000',
          name: 'Все населенные пункты'
        },
        ...city.data.data];
        this.list = listRes.data.data;
        this.meta = listRes.data.meta;

        this.categories = categoriesRes.data.data
          .map(tag => (
            {
              ...tag,
              active: !!(getParams?.type_id && getParams?.type_id == tag.id)
            }))
          .sort((a, b) => a.order - b.order);

        this.categories.unshift({
          id: '0000',
          code: 'all',
          color: {
            active: null,
            default: null
          },
          display_order: 0,
          name: 'Все категории',
          active: !getParams?.type_id
        });

        this.mapVenues = mapRes.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded = true;
      }
    },
    filterPeriod(Key, bool) {
      if (!Key) {
        // this.getParams.sessions_from = this.$dayjs().format("YYYY-MM-DD")
        this.getParams.sessions_from = null;
        this.getParams.sessions_to = null;
        this.periodTimeKey = null; // 'За всё время'
        if (this.getParams.sessions_from == null && this.getParams.sessions_to == null) {
          this.getParams.sessions_from = this.$dayjs()
            .format('YYYY-MM-DD');
          this.getParams.sessions_to = this.$dayjs()
            .add(3, 'month')
            .format('YYYY-MM-DD');
        }
        this.filterBy();
      } else {
        this.getParams.sessions_from = this.$dayjs()
          .startOf(Key)
          .format('YYYY-MM-DD');
        // this.getParams.sessions_to = this.$dayjs().add(1, 'day').format("YYYY-MM-DD")

        switch (Key) {
          case 'D':
            this.getParams.sessions_to = this.$dayjs()
              .add(1, 'day')
              .format('YYYY-MM-DD');
            break;
          case 'M':
            this.getParams.sessions_to = this.$dayjs()
              .endOf('month')
              .add(1, 'day')
              .format('YYYY-MM-DD');
            break;
          case 'w':
            this.getParams.sessions_to = this.$dayjs()
              .endOf('week')
              .add(1, 'day')
              .format('YYYY-MM-DD');
            break;
          default:
            break;
        }

        if (bool) {
          if (Key != '00000') {
            this.getParams.city_id = Key;
          } else {
            this.getParams.city_id = null;
          }
          this.periodGeoKey = Key;
        } else {
          this.periodTimeKey = Key;
        }

        this.filterBy();
      }
    },
    async filterBy(item = false) {
      if (item == true) {
        Object.assign(this.getParams, this.formatDayRange());
      }
      const [listRes, mapRes] = await Promise.all([
        this.$axios.get(API, { params: {...this.getParams, 'sort[is_main]': 'desc',
            'sort[started_at]': 'asc'}}),
        this.$axios.get('afisha/events/map', { params: this.getParams })
      ]);

      this.list = listRes.data.data;
      if (this.getParams.type_id != null) {
        // eslint-disable-next-line no-shadow
        const filterTrue = this.list.filter(item => item?.is_main === true);
        // eslint-disable-next-line no-shadow
        const filterFalse = this.list.filter(item => (item?.is_main ? item.is_main === false : true));
        this.list = [...filterTrue, ...filterFalse];
      }
      this.meta = listRes.data.meta;

      this.mapVenues = mapRes.data.data;
      this.count++;
    },
    async loadMore() {
      const { data } = await this.$axios.get(API, {
        params: {
          page: this.nextPage,
          ...this.getParams
        }
      });
      this.list.push(...data.data);
      if (this.getParams.type_id != null) {
        // eslint-disable-next-line no-shadow
        const filterTrue = this.list.filter(item => item?.is_main === true);
        // eslint-disable-next-line no-shadow
        const filterFalse = this.list.filter(item => (item?.is_main ? item.is_main === false : true));
        this.list = [...filterTrue, ...filterFalse];
      }
      this.meta = data.meta;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vc-container {
  border: 0;
}
@media (max-width: 524px) {
  .tags_wrapper {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .tags_wrapper {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    /* margin: 100%; */
    max-width: 100%;
  }
}
.h-400px{
  height: 400px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
<style lang="scss">
.ListItem.active::before {
  border-radius: 0px 3px 3px 0px;
}
.r-map-balloon {
  display: block;
  width: 244px;
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4EDFB;
    overflow: hidden;
  }
  &__info {
    padding: 12px 16px 0;
  }
}

</style>
