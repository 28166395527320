<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>
<script>
import styleAsString from '!!raw-loader!windi.css';

export default {
  data() {
    return {
      style: ''
    };
  },
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps') &&
    document.getElementById('vue-yandex-maps').remove();
    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  async created() {
    this.addStyle(styleAsString);
  },

  beforeDestroy() {
    if (this.style) {
      document.head.removeChild(this.style);
    }
  },
  methods: {
    addStyle(content) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = content;
      this.style = document.head.appendChild(style);
    }
  }
};
// export default {
//   data() {
//     return {};
//   },
//   created() {
//     this.setAuth();
//   },
//   methods: {
//     async setAuth() {
//       try {
//         const { data } = await this.$axios.get("/rir-auth", {
//           withCredentials: true,
//         });
//         console.log('TST', data.api_token);
//         sessionStorage.setItem('api_token', data.api_token);
//         // this.$axios.setToken(sessionStorage.api_token, "Bearer");
//       } catch (error) {
//         console.log(error);
//       }
//     },
//   },
// };
</script>
<style>
.RBulb__badge.fargo.marina.eluno.title.block {
  background-color: unset !important;
}
.RButtonSimple.RModal__close.larishae.transparent.rocky {
  background-color: unset !important;
}
.RButtonAction.rocky.sulguni {
  background-color: unset !important;
}
</style>
<style>
body {
  font-family: RirFont, sans-serif !important;
}
</style>