<template>
  <div
    class="flex-1"
    v-on="$listeners"
  >
    <button
      class="z-10 flex items-center focus:outline-none select-none"
      @click="open = !open"
    >
      <slot
        name="button"
        :open="open"
      />
    </button>

    <!-- to close when clicked on space around it-->
    <button
      class="fixed inset-0 h-full w-full cursor-default focus:outline-none"
      v-if="open"
      @click="open = false"
      tabindex="-1"
    />

    <!--dropdown menu
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"

    -->
    <transition>
      <!-- "placement === 'right' ? 'right-0' : 'left-0'" -->
      <div
        class="absolute z-10 mt-2 mobile_centered"
        :class="right ? 'right-0' : ''"
        v-if="open"
      >
        <slot
          name="content"
          :close="close"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    // placement: {
    //   type: String,
    //   default: "left",
    //   validator: (value) => ["right", "left"].indexOf(value) !== -1,
    // },
    right: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    };
  },
  mounted() {
    const onEscape = e => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.open = false;
      }
    };
    document.addEventListener('keydown', onEscape);
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', onEscape);
    });
  },
  methods: {
    close() {
      this.open = false;
    }
  }
};
</script>
<style scoped lang="scss">

.mobile_centered{
  &.right-0{
    right: -40% !important;
   }
  &.left-0{
    left: -40% !important;
  }
}

</style>
