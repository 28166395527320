import { render, staticRenderFns } from "./TagButton.vue?vue&type=template&id=13cad342&scoped=true&"
import script from "./TagButton.vue?vue&type=script&lang=js&"
export * from "./TagButton.vue?vue&type=script&lang=js&"
import style0 from "./TagButton.vue?vue&type=style&index=0&id=13cad342&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13cad342",
  null
  
)

export default component.exports