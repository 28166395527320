import Vue from 'vue';
import VueRouter from 'vue-router';
import List from '../views/List.vue';
import Page404 from '../views/Page404';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'List',
    component: List
  },
  { path: '/:id', name: 'Item', component: () => import('../views/Item.vue') },
  { path: '*', name: 'Page404', component: Page404 }
];

// https://router.vuejs.org/ru/guide/advanced/scroll-behavior.html#%D0%BF%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F-%D0%BF%D1%80%D0%BE%D0%BA%D1%80%D1%83%D1%82%D0%BA%D0%B0
const router = new VueRouter({
  mode: 'history',
  base: 'events', // process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition)
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    };
  }
});

export default router;
