import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import VueSocialSharing from 'vue-social-sharing';
import dayjs from 'dayjs';
import axios from 'axios';
import router from './router';
import store from './store';
import rir from './plugins/RirLib';
import YmapPlugin from './plugins/vue-yandex-maps/vue-yandex-maps.umd';

import 'windi.css';
import './assets/main.css';

import App from './App.vue';

const settings = {
  apiKey: '0f707b9a-62ed-46b5-98ea-b56b14c0fc31',
  lang: 'ru_RU',
  coordorder: 'latlong', // - default Ya
  version: '2.1'
};
Vue.use(YmapPlugin, settings);
Vue.use(VueSocialSharing);
Vue.prototype.$cityCenter = Array.isArray(window?.__CONFIG__?.cityCenter?.latLng)
  ? [...window?.__CONFIG__?.cityCenter?.latLng] // .reverse()
  : process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);
axios.defaults.baseURL = process.env.NODE_ENV
 === 'development'
  ? 'https://act.rosatom.city/api/'
  : '/api/';
Vue.prototype.$axios = axios;

const S3_COMMON = window?.__CONFIG__?.storage
  ? `${window.__CONFIG__.storage}/common/vue`
  : process.env.VUE_APP_S3_COMMON_PATH;

Vue.prototype.$S3_COMMON = S3_COMMON;

require('dayjs/locale/ru');

dayjs.locale('ru');
dayjs.extend(require('dayjs/plugin/relativeTime'));
dayjs.extend(require('dayjs/plugin/quarterOfYear'));
dayjs.extend(require('dayjs/plugin/advancedFormat'));

Vue.prototype.$dayjs = dayjs;

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        }
      });
    },
    router,
    store,
    $rir: rir
  }
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
