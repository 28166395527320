<template>
  <div class="r-map-balloon  pointer">
    <div
      class="r-map-balloon__img"
      style=""
    >
      <img
        :src="marker.photo1"
        :alt="marker.typeS"
        v-if="!!marker.photo1"
      >
      <r-icon
        v-else
        icon="trade-objects"
        fill="alien"
        size="32"
      />
    </div>
    <div class="pa-4">
      <p class="roquefort mb-1">
        {{ marker.typeS }}
      </p>
      <p class="сaprino opacity-72">
        {{ marker.address }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  @media(max-width: 491px) {
    width: 100%;
  }
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4EDFB;
    overflow: hidden;
  }
  &__info {
    padding: 12px 16px 0;
  }
}

</style>
