var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('main',{staticClass:"mb-10"},[_c('h1',{staticClass:"cheddar"},[_vm._v(" Календарь событий ")]),_c('div',{staticClass:"mt-8 mb-4 flex justify-between tags_wrapper"},[_c('tag-carousel',{attrs:{"items":_vm.categories},on:{"select":_vm.onChangeType,"selectFree":_vm.onChangeTypeFree}})],1),_c('div',{staticClass:"mt-5 mb-5 flex justify-between tags_wrapper"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"opacity-48"},[_vm._v(" Показывать ")]),_c('c-dropdown',{staticClass:"ml-3 mr-3",scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"flex items-center",on:{"click":function($event){_vm.dropdownAsDatePicker = false}}},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.dropdownAsDatePicker ? "За период" : _vm.periodTimeObj[_vm.periodTimeKey] || "За всё время")+" ")]),_c('svg',{staticClass:"w-4 h-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"}})])])]},proxy:true},{key:"content",fn:function({ close }){return [(!_vm.dropdownAsDatePicker)?_c('nav',{staticClass:"flex flex-col w-42 bg-white shadow-lg rounded-8 bg-white",staticStyle:{"z-index":"1111111"}},[_c('c-list-item',{attrs:{"active":!_vm.getParams.sessions_to},on:{"click":function($event){_vm.filterPeriod(null, false);
                close();}}},[_vm._v(" За все время ")]),_vm._l((_vm.periodTimeObj),function(Val,Key,idx){return _c('c-list-item',{key:idx,attrs:{"active":_vm.periodTimeKey === Key},on:{"click":function($event){_vm.filterPeriod(Key, false);
                close();}}},[_vm._v(" "+_vm._s(Val)+" ")])}),_c('c-list-item',{on:{"click":function($event){_vm.dropdownAsDatePicker = true}}},[_c('span',{staticClass:"text-rocky"},[_vm._v("Указать даты")])])],2):_c('div',{staticClass:"flex flex-col shadow shadow-lg bg-white rounded-8"},[_c('date-picker',{attrs:{"is-range":""},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('button',{staticClass:"button mb-4 mx-4",on:{"click":function($event){_vm.filterBy(true);
                close();}}},[_vm._v(" Искать ")])],1)]}}],null,false,1356482994)})],1),_c('c-dropdown',{attrs:{"left":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_c('r-icon',{attrs:{"icon":"geopoint","fill":"rocky"}}),_c('span',{staticClass:"ml-1 mr-2 text-rocky"},[_vm._v(" "+_vm._s(_vm.periodGeoObj.filter(item => item?.id == _vm.periodGeoKey)[0].name || "Все населенные пункты")+" ")]),_c('svg',{staticClass:"w-4 h-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"rocky"}},[_c('path',{attrs:{"d":"M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z","fill":"#3D75E4"}})])],1)]},proxy:true},{key:"content",fn:function({ close }){return [_c('nav',{staticClass:"flex flex-col w-62 bg-white shadow-lg rounded-8 bg-white"},_vm._l((_vm.periodGeoObj),function(Val,Key,idx){return _c('c-list-item',{key:idx,attrs:{"active":_vm.periodGeoKey === Val.id},on:{"click":function($event){_vm.filterPeriod(Val.id, true);
              close();}}},[_vm._v(" "+_vm._s(Val.name)+" ")])}),1)]}}],null,false,54060841)}),_c('rirSwitcher',{attrs:{"label":"Карта","value":_vm.success},on:{"input":_vm.successCheck,"itemCheck":_vm.isOpenCheck}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.success)?_c('div',{staticClass:"my-5"},[_c('r-map',{staticClass:"h-400px",attrs:{"zoom":12,"map-search":""}},_vm._l((_vm.ymapMarkers),function(marker,idx){return _c('ymap-marker',{key:_vm.count,attrs:{"coords":marker.center,"hint-content":marker.address,"cluster-name":"main","icon":{
            layout: 'default#image',
            imageHref:
              'https://storage.rosatom.city/platform-test/vue-culture/map/marker.svg',
          },"options":{ hideIconOnBalloonOpen: false },"balloon":{ body: _vm.clusterBalloon(marker)}}})}),1)],1):_vm._e()]),(!_vm.list.length)?_c('center',{staticClass:"h-screen-sm flex"},[_c('h3',{staticClass:"taleggio m-auto"},[_vm._v(" Ни одно событие не удовлетворяет "),_c('br'),_vm._v("условиям фильтра ")])]):_vm._e(),_c('transition-group',{staticClass:"grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8",attrs:{"name":"fade"}},_vm._l((_vm.list),function(It){return _c('card-event',{key:It.id,attrs:{"it":It}})}),1),(_vm.hasLoadMore)?_c('button',{staticClass:"button mt-10 w-full",on:{"click":_vm.loadMore}},[_vm._v(" Показать ещё ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }