var render = function render(){var _vm=this,_c=_vm._self._c;return _c('RouterLink',{staticClass:"group",attrs:{"to":`/${_vm.It.id}`}},[_c('figure',[_c('div',{staticClass:"relative",staticStyle:{"margin-top":"12px"}},[_c('picture',{staticClass:"overflow-hidden rounded-16 block aspect-10/16"},[_c('div',{staticClass:"object-fill min-h-full min-w-full bg-no-repeat bg-cover bg-center blur-10 filter",style:({
            backgroundImage: `url(${_vm.It.image_previews.medium})`,
          })}),_c('div',{staticClass:"object-fill min-h-full min-w-full bg-no-repeat bg-contain bg-center z-1 absolute inset-0",style:({
            backgroundImage: `url(${_vm.It.image_previews.medium})`,
          })})]),_c('c-tag',{staticClass:"absolute left-4 top-4",staticStyle:{"z-index":"1"}},[_vm._v(" "+_vm._s(_vm.It.type.name)+" ")]),(_vm.It.sale_available && _vm.It.price_from)?_c('c-tag',{key:"Sale",staticClass:"absolute right-4",staticStyle:{"z-index":"1","margin-top":"-40px"},attrs:{"bg":"snatch"}},[_vm._v(" "+_vm._s(_vm.It.price_from != _vm.It.price_to ? `от ${_vm.It.price_from} ₽` : `цена: ${_vm.It.price_from} ₽`)+" ")]):_vm._e(),(
          (!_vm.It.sale_available && _vm.It.price_from) ||
            (_vm.It.yabilet && !_vm.It.sale_available && !_vm.It.price_from)
        )?_c('c-tag',{key:"WTF",staticClass:"absolute right-4",staticStyle:{"z-index":"1","margin-top":"-40px"},attrs:{"bg":_vm.It.price_from == null ? 'seven' : 'snatch'}},[_vm._v(" "+_vm._s(_vm.It.price_from != _vm.It.price_to ? _vm.It.price_from == null ? 'цена:уточняйте на месте' : `от ${_vm.It.price_from} ₽` : _vm.It.price_from == null ? 'цена:уточняйте на месте' : `цена: ${_vm.It.price_from} ₽`)+" ")]):_vm._e(),(!_vm.It.yabilet && !_vm.It.sale_available && !_vm.It.price_from)?_c('c-tag',{key:"IsFree",staticClass:"absolute right-4 bottom-4",staticStyle:{"z-index":"1"},attrs:{"bg":"seven"}},[_vm._v(" Бесплатно ")]):_vm._e()],1),_c('h3',{staticClass:"over taleggio mt-4 mb-2 group-hover:text-interstellar",on:{"mouseover":function($event){_vm.isOpen=true},"mouseout":function($event){_vm.isOpen=false}}},[_vm._v(" "+_vm._s(_vm.It.title)+" ")]),_c('div',{staticClass:"mb-2 mozzarella opacity-48"},[_vm._v(" "+_vm._s(_vm.It.started_at && `${_vm.$dayjs(_vm.It.started_at).format("D MMMM")}`)+" "+_vm._s(_vm.It.ending_at && ` - ${_vm.$dayjs(_vm.It.ending_at).format("D MMMM")}`)+" ")]),_c('div',{staticClass:"line-clamp-2 mozzarella opacity-48 group-hover:opacity-64",domProps:{"textContent":_vm._s(_vm.getDescription(_vm.It))}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }